<template>
	<!-- 项目节点设置 -->
	<div class="">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">监控清单</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;margin-top:-7px">
				<el-form>
					<el-form-item>
						<el-row>
							<el-col :span="pageSpan">
								<el-button v-preventReClick class="btn" type="primary" size="small" v-if="showAdd"
									@click="addOrUpdateHandle()">新增</el-button>
							</el-col>
						</el-row>
					</el-form-item>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -26px; height: 570px;" body-style="height:530px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="510" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%; margin-bottom: 20px;"
								:cell-style="{ padding: '0px' }" header-cell-class-name="stepPointTHeader">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="monitorContent" label="监控内容" align="left" header-align="center">
								</el-table-column>
								<el-table-column prop="insUserName" label="登记人" width="110" align="center">
								</el-table-column>
								<el-table-column prop="insDate" label="登记时间" width="180" align="center">
								</el-table-column>
								<el-table-column prop="updDate" label="最后修改时间" width="180" align="center">
								</el-table-column>
								<el-table-column fixed="right" header-align="center" align="center" width="200" label="操作"
									v-if="showEdit || showDelete">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="mini"
											@click="addOrUpdateHandle(scope.row.recNo)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="mini"
											@click="deleteHandle(scope.row.recNo)" v-if="showDelete">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>
<script>
import AddOrUpdate from './monitor-add-or-update'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			pageSpan: 12,
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			addOrUpdateVisible: false,
			dataList: [],
			dataListSelections: [],
			dataForm: {
				workNo: ""
			},
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		};
	},
	components: {
		AddOrUpdate,
	},
	mounted() {
		this.showSearch = $common.isAuth('monitor:search');
		this.showAdd = $common.isAuth('monitor:add');
		this.showEdit = $common.isAuth('monitor:edit');
		this.showDelete = $common.isAuth('monitor:delete');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		if (this.showSearch) {
			this.getDataList();
		}
		this.pageIndex2 = 1;
	},
	methods: {
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectmonitorinfo/list",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
					subjectNo: $common.getItem("subjectNo"),
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				} else {
					this.dataList = [];
					this.totalPage = 0
					this.pageIndex2 = 1
				}
			})
		},
		selectionChangeHandle(val) {
			this.dataListSelections = val
		},
		//自适应高度
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 160;
			return {
				height: height + 'px'
			}
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val;
			this.pageIndex = 1;
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val;
			this.getDataList()
		},
		// 新增节点
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true;
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		// 删除
		deleteHandle(id) {
			var ids = id ? [id] : this.dataListSelections.map(item => {
				return item.recNo
			});
			// let ids = [id];
			this.$confirm(`您确定对选中监控信息进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectmonitorinfo/delete/" + id,
					method: 'post',
					data: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped>
.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}
</style>
