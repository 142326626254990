<template>
	<el-dialog :title="!dataForm.nodeId ? '新增监控内容' : '修改监控内容'" :close-on-click-modal="false" :visible.sync="visible"
		width="620px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-form-item label="监控内容" prop="monitorContent" class="item">
					<el-input class="selItemInput descAreaLg" type="textarea" placeholder="描述一下监控内容" rows="4"
						v-model="dataForm.monitorContent"></el-input>
				</el-form-item>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick @click="visible = false" class="rebtn" size="small">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "monitor-add-or-update",
	data() {
		return {
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 5,
			// nodeinfoList : [],
			visible: false,
			inline: false,
			activeNames: ['1'],
			plannedTime: '',
			description: '',
			dataForm: {
				recNo: '',
				workNo: '',
				subjectNo: '',
				monitorContent: '',
			},
			dataRule: {
				monitorContent: [
					{ required: true, message: "请填写监控内容", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		init(id) {
			this.visible = true;
			this.dataForm = {
				recNo: '',
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				monitorContent: '',
			},
				this.$nextTick(() => {
					this.dataForm.recNo = id ? id : 0;
					if (this.dataForm.recNo) {
						this.$http({
							url: this.$store.state.httpUrl + `/business/projectmonitorinfo/info/${this.dataForm.recNo}`,
							method: 'get',
							params: this.$http.adornParams()
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.dataForm = data.body;
							}
						})
					}
				});
			this.$refs["dataForm"].resetFields();
		},
		// 表单提交
		dataFormSubmit() {
			// console.log("表单提交")
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					this.$http({
						url: this.$store.state.httpUrl +
							`/business/projectmonitorinfo/${!this.dataForm.recNo ?
								"save" : "update"}`,
						method: "post",
						data: this.$http.adornData({
							workNo: $common.getItem("workNo"),
							subjectNo: $common.getItem("subjectNo"),
							recNo: this.dataForm.recNo,
							monitorContent: this.dataForm.monitorContent,
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},
	}
}
</script>
<style scoped>
.selItemInput.descAreaLg {
	width: 380px !important;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
	/* display: flex;
	justify-content: center;
	align-items: center; */
}

.border-form .el-row.set_border {
	border: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
